// SnackList.js
const snackData = [
  {
    id: 1,
    name: '치킨 육포',
    category: 'safe', // 먹어도되는 간식은 'safe'
    img: '../Snack/ChickinJerky.png',
    diet: 'diet-friendly', // 'diet-friendly' 또는 'non-diet-friendly'로 나누기
    benefits: ['bone', 'energy recovery'], // 건강 페이지와 연계된 건강에 이로운 부위
    shortDescription: '뼈와 기력회복에 좋습니다.', // 간식 한줄 요약
    detailedDescription:
      '이 간식은 고단백질입니다. 뼈와 기력회복에 좋습니다. 소형견 기준 적정 제공량 : ~~~ / 중형견 기준 적정 제공량 : ~~~ / 대형견 기준 적정 제공량 : ~~~', // 간식 자세한 설명
  },
  {
    id: 2,
    name: '초콜릿',
    category: 'unsafe', // 먹으면 안되는 간식은 'unsafe'
    img: '../Snack/Chocolate.jpg',
    benefits: [],
    shortDescription: '강아지에 해롭습니다.',
    detailedDescription:
      '초콜릿에는 테오브로민이 함유되어 있어 반려견에게 독성이 있고 심각한 건강 문제를 일으킬 수 있습니다.',
  },
  {
    id: 3,
    name: '당근',
    category: 'safe',
    img: '../Snack/Carrot.png',
    diet: 'diet-friendly',
    benefits: ['eyes'],
    shortDescription: '눈 건강에 좋고 칼로리가 낮습니다.',
    detailedDescription:
      '당근에는 베타카로틴이 풍부하여 눈 건강에 도움이 되고 저칼로리 식단을 지원합니다.',
  },
  {
    id: 4,
    name: '블루베리',
    category: 'safe',
    img: '../Snack/Blueberry.png',
    diet: 'diet-friendly',
    benefits: ['eyes'],
    shortDescription: '항산화 성분이 풍부하여 눈 건강에 좋습니다.',
    detailedDescription:
      '블루베리는 항산화제가 풍부하여 시력 보호와 면역력 강화에 도움이 됩니다.',
  },
  {
    id: 5,
    name: '호박',
    category: 'safe',
    img: '../Snack/Pumpkin.png',
    diet: 'diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화에 좋고 변비를 예방합니다.',
    detailedDescription:
      '호박은 섬유질이 풍부하여 소화 기능을 개선하고 변비 예방에 효과적입니다.',
  },
  {
    id: 6,
    name: '사과',
    category: 'safe',
    img: '../Snack/Apple.png',
    diet: 'diet-friendly',
    benefits: ['teeth'],
    shortDescription: '치아 건강과 입 냄새 제거에 좋습니다.',
    detailedDescription:
      '사과는 자연적인 치석 제거를 도와주며, 입 냄새를 줄여줍니다.',
  },
  {
    id: 7,
    name: '고구마',
    category: 'safe',
    img: '../Snack/SweetPotato.png',
    diet: 'non-diet-friendly',
    benefits: ['energy recovery'],
    shortDescription: '에너지 회복에 도움을 줍니다.',
    detailedDescription:
      '고구마는 천연 탄수화물이 풍부하여 피로 회복과 에너지 공급에 유용합니다.',
  },
  {
    id: 8,
    name: '익힌 소고기 간',
    category: 'safe',
    img: '../Snack/SeasonedCookedBeef.png',
    diet: 'non-diet-friendly',
    benefits: ['bones', 'joints'],
    shortDescription: '뼈와 관절 건강에 좋습니다.',
    detailedDescription:
      '소 간은 철분과 비타민이 풍부하여 뼈와 관절 건강에 도움을 줍니다.',
  },
  {
    id: 9,
    name: '강낭콩',
    category: 'safe',
    img: '../Snack/KidneyBean.png',
    diet: 'diet-friendly',
    benefits: ['weight control'],
    shortDescription: '다이어트와 체중 조절에 좋습니다.',
    detailedDescription:
      '강낭콩은 칼로리가 낮고 포만감을 줘서 체중 조절에 효과적입니다.',
  },
  {
    id: 10,
    name: '요거트',
    category: 'safe',
    img: '../Snack/Yogurt.png',
    diet: 'non-diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화기 건강을 지원합니다.',
    detailedDescription:
      '요거트는 프로바이오틱스가 풍부하여 소화기 건강을 지원하고 장내 균형을 잡아줍니다.',
  },
  {
    id: 11,
    name: '오리고기',
    category: 'safe',
    img: '../Snack/DuckMeat.png',
    diet: 'non-diet-friendly',
    benefits: ['joints'],
    shortDescription: '관절 건강을 개선합니다.',
    detailedDescription:
      '오리고기는 관절 강화에 좋은 글루코사민을 포함하고 있어 관절 건강에 좋습니다.',
  },
  {
    id: 12,
    name: '바나나',
    category: 'safe',
    img: '../Snack/Banana.png',
    diet: 'diet-friendly',
    benefits: ['energy recovery'],
    shortDescription: '피로 회복과 에너지 충전에 좋습니다.',
    detailedDescription:
      '바나나는 천연 당분이 있어 피로 회복과 에너지 충전에 유익합니다.',
  },
  {
    id: 13,
    name: '연어 껍질',
    category: 'safe',
    img: '../Snack/SalmonSkin.png',
    diet: 'non-diet-friendly',
    benefits: ['coat', 'skin'],
    shortDescription: '피부와 털 건강을 개선합니다.',
    detailedDescription:
      '연어 껍질은 오메가-3 지방산이 풍부하여 피부와 털 건강에 도움을 줍니다.',
  },
  {
    id: 14,
    name: '브로콜리',
    category: 'safe',
    img: '../Snack/Broccoli.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '면역력 강화에 도움을 줍니다.',
    detailedDescription:
      '브로콜리는 비타민 C와 항산화제가 풍부하여 면역력 강화에 좋습니다.',
  },
  {
    id: 15,
    name: '치즈',
    category: 'safe',
    img: '../Snack/Cheese.png',
    diet: 'non-diet-friendly',
    benefits: ['bones'],
    shortDescription: '뼈 건강에 좋습니다.',
    detailedDescription:
      '치즈는 칼슘이 풍부하여 뼈 강화에 유용하지만 다이어트에는 적합하지 않습니다.',
  },
  {
    id: 16,
    name: '오이',
    category: 'safe',
    img: '../Snack/Cucumber.png',
    diet: 'diet-friendly',
    benefits: ['hydration'],
    shortDescription: '수분 공급에 좋습니다.',
    detailedDescription:
      '오이는 수분 함량이 높아 체내 수분 공급에 탁월한 효과를 줍니다.',
  },
  {
    id: 17,
    name: '수박',
    category: 'safe',
    img: '../Snack/Watermelon.png',
    diet: 'diet-friendly',
    benefits: ['hydration', 'eyes'],
    shortDescription: '수분 보충과 눈 건강에 좋습니다.',
    detailedDescription:
      '수박은 수분이 풍부하고 비타민 A를 포함해 눈 건강에 좋습니다.',
  },
  {
    id: 18,
    name: '파인애플',
    category: 'safe',
    img: '../Snack/Pineapple.png',
    diet: 'non-diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화에 도움을 줍니다.',
    detailedDescription: '파인애플은 소화를 돕는 브로멜라인 효소가 풍부합니다.',
  },
  {
    id: 19,
    name: '익힌 애호박',
    category: 'safe',
    img: '../Snack/Squash.png',
    diet: 'diet-friendly',
    benefits: ['weight control'],
    shortDescription: '체중 조절에 효과적입니다.',
    detailedDescription:
      '익힌 애호박은 칼로리가 낮고 수분이 많아 체중 감량에 좋습니다.',
  },
  {
    id: 20,
    name: '크랜베리',
    category: 'safe',
    img: '../Snack/Cranberry.png',
    diet: 'non-diet-friendly',
    benefits: ['urinary tract'],
    shortDescription: '요로 건강을 지원합니다.',
    detailedDescription:
      '크랜베리는 요로 건강에 도움을 주는 항산화 성분이 풍부합니다.',
  },
  {
    id: 21,
    name: '계란 노른자',
    category: 'safe',
    img: '../Snack/EggYolk.png',
    diet: 'non-diet-friendly',
    benefits: ['coat', 'skin'],
    shortDescription: '피부와 털 건강을 개선합니다.',
    detailedDescription:
      '달걀 노른자는 비타민과 지방이 풍부해 피부와 털 건강에 좋습니다.',
  },
  {
    id: 22,
    name: '커피',
    category: 'unsafe',
    img: '../Snack/Coffee.png',
    benefits: [],
    shortDescription: '카페인 함유로 강아지에게 유해합니다.',
    detailedDescription:
      '커피는 강아지에게 해로운 카페인이 포함되어 있어 심장박동을 빠르게 하고 불안, 구토, 심지어 사망까지 유발할 수 있습니다.',
  },
  {
    id: 23,
    name: '포도',
    category: 'unsafe',
    img: '../Snack/Grape.png',
    benefits: [],
    shortDescription: '포도와 건포도는 신장 손상을 일으킬 수 있습니다.',
    detailedDescription:
      '포도와 건포도는 강아지의 신장 기능을 빠르게 악화시키며, 소량으로도 중독 증상을 유발할 수 있어 치명적입니다.',
  },
  {
    id: 24,
    name: '양파',
    category: 'unsafe',
    img: '../Snack/Onion.png',
    benefits: [],
    shortDescription: '강아지의 적혈구를 손상시킬 수 있습니다.',
    detailedDescription:
      '양파와 마늘에는 티오설페이트가 포함되어 있어 강아지의 적혈구를 파괴하고 빈혈을 일으킬 수 있습니다.',
  },
  {
    id: 25,
    name: '마카다미아 너트',
    category: 'unsafe',
    img: '../Snack/MacadamiaNuts.png',
    benefits: [],
    shortDescription: '마카다미아 너트는 중독 증상을 일으킵니다.',
    detailedDescription:
      '마카다미아 너트는 강아지에게 구토, 근육 약화, 떨림 및 발열을 유발할 수 있으며, 신경계에 심각한 영향을 미칩니다.',
  },
  {
    id: 26,
    name: '아보카도',
    category: 'unsafe',
    img: '../Snack/Avocado.png',
    benefits: [],
    shortDescription: '아보카도에는 퍼신이라는 독성 물질이 포함되어 있습니다.',
    detailedDescription:
      '아보카도의 퍼신 성분은 강아지의 소화기와 심장에 심각한 문제를 일으킬 수 있으며, 중독 시 복통, 구토, 설사를 유발합니다.',
  },
  {
    id: 27,
    name: '베이컨',
    category: 'unsafe',
    img: '../Snack/Bacon.png',
    benefits: [],
    shortDescription: '베이컨은 염분과 지방이 높아 건강에 유해합니다.',
    detailedDescription:
      '베이컨은 강아지의 췌장염을 유발할 수 있는 높은 염분과 지방을 포함하고 있어, 섭취 시 건강에 치명적입니다.',
  },
  {
    id: 28,
    name: '치즈',
    category: 'unsafe',
    img: '../Snack/Cheese.png',
    benefits: [],
    shortDescription: '치즈는 유당 불내증을 일으킬 수도 있습니다.',
    detailedDescription:
      '치즈는 많은 강아지에게 소화 문제를 일으킬 수 있으며, 유당 불내증이 있는 경우 설사 및 복통을 유발할 수 있습니다.',
  },
  {
    id: 29,
    name: '호두',
    category: 'unsafe',
    img: '../Snack/Walnut.png',
    benefits: [],
    shortDescription: '소화 문제를 일으킬 수 있습니다.',
    detailedDescription:
      '소화 장애 및 중독을 일으킬 수 있어 주의가 필요합니다.',
  },
  {
    id: 30,
    name: '아스파라거스',
    category: 'safe',
    img: '../Snack/Asparagus.png',
    diet: 'diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화에 도움을 줍니다.',
    detailedDescription: '섬유질이 풍부해 소화기 건강에 유익합니다.',
  },
  {
    id: 31,
    name: '꿀',
    category: 'unsafe',
    img: '../Snack/Honey.png',
    benefits: [],
    shortDescription: '혈당 문제를 유발할 수 있습니다.',
    detailedDescription: '당 함량이 높아 혈당 문제를 유발할 수 있습니다.',
  },
  {
    id: 32,
    name: '라임',
    category: 'unsafe',
    img: '../Snack/Lime.png',
    benefits: [],
    shortDescription: '위장 장애를 일으킬 수 있습니다.',
    detailedDescription: '산성이 강해 위장에 무리를 줄 수 있습니다.',
  },
  {
    id: 33,
    name: '단호박',
    category: 'safe',
    img: '../Snack/Pumpkin.png',
    diet: 'diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화기 건강에 좋습니다.',
    detailedDescription: '섬유질이 소화 기능을 개선하는 데 도움을 줍니다.',
  },
  {
    id: 34,
    name: '시금치',
    category: 'safe',
    img: '../Snack/Spinach.png',
    diet: 'diet-friendly',
    benefits: ['bones'],
    shortDescription: '뼈 건강에 좋습니다.',
    detailedDescription: '비타민 K가 풍부하여 뼈 건강에 유익합니다.',
  },
  {
    id: 35,
    name: '소시지',
    category: 'unsafe',
    img: '../Snack/Sausage.png',
    benefits: [],
    shortDescription: '염분이 높고 건강에 해롭습니다.',
    detailedDescription: '염분과 지방이 강아지 건강에 해로울 수 있습니다.',
  },
  {
    id: 36,
    name: '오트밀',
    category: 'safe',
    img: '../Snack/Oatmeal.png',
    diet: 'diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화 기능에 좋습니다.',
    detailedDescription:
      '오트밀은 식이섬유가 풍부하여 소화 기능 개선에 도움이 됩니다.',
  },
  {
    id: 37,
    name: '생선',
    category: 'safe',
    img: '../Snack/Fish.png',
    diet: 'non-diet-friendly',
    benefits: ['coat', 'skin'],
    shortDescription: '피부와 털 건강에 좋습니다.',
    detailedDescription:
      '생선은 오메가-3 지방산이 풍부하여 털과 피부 건강을 개선합니다.',
  },
  {
    id: 38,
    name: '감자칩',
    category: 'unsafe',
    img: '../Snack/PotatoChips.png',
    benefits: [],
    shortDescription: '염분과 지방이 높아 강아지에게 해롭습니다.',
    detailedDescription:
      '감자칩은 염분과 지방 함량이 높아 건강에 좋지 않습니다.',
  },
  {
    id: 39,
    name: '멜론',
    category: 'safe',
    img: '../Snack/Melon.png',
    diet: 'diet-friendly',
    benefits: ['hydration'],
    shortDescription: '수분 공급에 좋습니다.',
    detailedDescription: '멜론은 수분 함량이 높아 체내 수분 보충에 유용합니다.',
  },
  {
    id: 40,
    name: '스테비아',
    category: 'unsafe',
    img: '../Snack/SteviaLeaf.png',
    benefits: [],
    shortDescription: '강아지에게 소화 불편을 일으킬 수 있습니다.',
    detailedDescription:
      '스테비아는 강아지에게 소화 장애를 유발할 수 있어 주의가 필요합니다.',
  },
  {
    id: 41,
    name: '무',
    category: 'safe',
    img: '../Snack/Radish.png',
    diet: 'diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화에 도움을 줍니다.',
    detailedDescription: '무는 소화를 돕는 효소가 풍부해 소화 건강에 좋습니다.',
  },
  {
    id: 42,
    name: '익힌 연어',
    category: 'safe',
    img: '../Snack/CookedSalmon.png',
    diet: 'non-diet-friendly',
    benefits: ['coat', 'skin'],
    shortDescription: '피부와 털 건강에 좋습니다.',
    detailedDescription:
      '오메가-3 지방산이 풍부하여 털과 피부 건강에 도움을 줍니다.',
  },
  {
    id: 43,
    name: '바질',
    category: 'safe',
    img: '../Snack/Basil.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '면역력을 높이는 데 좋습니다.',
    detailedDescription:
      '바질은 항산화 성분이 있어 면역력 강화에 도움을 줍니다.',
  },
  {
    id: 44,
    name: '생강',
    category: 'unsafe',
    img: '../Snack/Ginger.png',
    benefits: [],
    shortDescription: '강아지에게 위장 장애를 일으킬 수 있습니다.',
    detailedDescription:
      '생강은 위장을 자극할 수 있어 강아지에게 적합하지 않습니다.',
  },
  {
    id: 45,
    name: '석류',
    category: 'safe',
    img: '../Snack/Pomegranate.png',
    diet: 'diet-friendly',
    benefits: ['antioxidant'],
    shortDescription: '항산화 성분이 풍부합니다.',
    detailedDescription:
      '석류는 강력한 항산화 성분이 포함되어 있어 세포 건강에 유익합니다.',
  },
  {
    id: 46,
    name: '페퍼민트',
    category: 'unsafe',
    img: '../Snack/Peppermint.png',
    benefits: [],
    shortDescription: '위장에 자극을 줄 수 있습니다.',
    detailedDescription:
      '페퍼민트는 강아지에게 소화 불편을 초래할 수 있습니다.',
  },
  {
    id: 47,
    name: '콜리플라워',
    category: 'safe',
    img: '../Snack/Cauliflower.png',
    diet: 'diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화 건강에 좋습니다.',
    detailedDescription:
      '콜리플라워는 소화에 도움을 주는 식이섬유가 풍부합니다.',
  },
  {
    id: 48,
    name: '쌀밥',
    category: 'safe',
    img: '../Snack/Rice.png',
    diet: 'diet-friendly',
    benefits: ['energy recovery'],
    shortDescription: '기력 회복에 좋습니다.',
    detailedDescription:
      '쌀밥은 소화가 잘 되어 에너지를 빠르게 보충할 수 있습니다.',
  },
  {
    id: 49,
    name: '페퍼',
    category: 'unsafe',
    img: '../Snack/Pepper.png',
    benefits: [],
    shortDescription: '위장을 자극할 수 있습니다.',
    detailedDescription:
      '페퍼는 위장을 자극하여 소화 불편을 초래할 수 있습니다.',
  },
  {
    id: 50,
    name: '코코넛',
    category: 'safe',
    img: '../Snack/Coconut.png',
    diet: 'diet-friendly',
    benefits: ['coat', 'skin'],
    shortDescription: '피부와 털 건강에 좋습니다.',
    detailedDescription:
      '코코넛은 천연 오일과 영양소가 풍부하여 피부와 털 건강에 유익합니다.',
  },
  {
    id: 51,
    name: '블랙베리',
    category: 'safe',
    img: '../Snack/Blackberry.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '면역력 강화에 좋습니다.',
    detailedDescription:
      '블랙베리는 항산화 성분이 풍부하여 면역력에 도움이 됩니다.',
  },
  {
    id: 52,
    name: '해바라기씨',
    category: 'safe',
    img: '../Snack/SunflowerSeeds.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '면역력 강화에 좋습니다.',
    detailedDescription:
      '해바라기씨는 비타민 E가 풍부하여 면역력에 도움을 줍니다.',
  },
  {
    id: 53,
    name: '망고',
    category: 'safe',
    img: '../Snack/MangoSlice.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '면역력 강화에 도움을 줍니다.',
    detailedDescription:
      '망고는 비타민 C와 베타카로틴이 풍부해 면역력 증진에 유익합니다.',
  },
  {
    id: 54,
    name: '피넛 버터',
    category: 'safe',
    img: '../Snack/PeanutButter.png',
    diet: 'non-diet-friendly',
    benefits: ['energy recovery'],
    shortDescription: '에너지 보충에 유익합니다.',
    detailedDescription:
      '피넛 버터는 천연 지방이 포함되어 있어 강아지의 에너지를 충전하는 데 도움이 됩니다.',
  },
  {
    id: 55,
    name: '고양이 사료',
    category: 'unsafe',
    img: '../Snack/CatFood.png',
    benefits: [],
    shortDescription: '강아지에게 맞지 않는 성분이 포함되어 있습니다.',
    detailedDescription:
      '고양이 사료는 단백질과 지방 함량이 높아 강아지에게 해로울 수 있습니다.',
  },
  {
    id: 56,
    name: '익힌 칠면조 고기',
    category: 'safe',
    img: '../Snack/TurkeyMeat.png',
    diet: 'diet-friendly',
    benefits: ['muscle'],
    shortDescription: '근육 발달에 좋습니다.',
    detailedDescription:
      '칠면조 고기는 고단백 저지방 식품으로 근육 발달을 돕습니다.',
  },
  {
    id: 57,
    name: '후추',
    category: 'unsafe',
    img: '../Snack/BlackPepper.png',
    benefits: [],
    shortDescription: '위장에 자극을 줄 수 있습니다.',
    detailedDescription:
      '후추는 강아지의 위장에 자극을 줄 수 있으므로 피하는 것이 좋습니다.',
  },
  {
    id: 58,
    name: '아마씨',
    category: 'safe',
    img: '../Snack/Flaxseed.png',
    diet: 'diet-friendly',
    benefits: ['coat', 'skin'],
    shortDescription: '털과 피부 건강에 좋습니다.',
    detailedDescription:
      '아마씨는 오메가-3 지방산이 풍부하여 털과 피부 건강을 유지하는 데 도움을 줍니다.',
  },
  {
    id: 60,
    name: '알코올',
    category: 'unsafe',
    img: '../Snack/RedWine.png',
    benefits: [],
    shortDescription: '알코올은 강아지에게 매우 해롭습니다.',
    detailedDescription:
      '알코올은 강아지의 신경계에 부정적인 영향을 미칠 수 있습니다.',
  },
  {
    id: 61,
    name: '치커리',
    category: 'safe',
    img: '../Snack/Chicory.png',
    diet: 'diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화에 좋습니다.',
    detailedDescription:
      '치커리는 식이섬유가 풍부하여 소화를 돕고 장 건강을 증진시킵니다.',
  },
  {
    id: 62,
    name: '파슬리',
    category: 'safe',
    img: '../Snack/Parsley.png',
    diet: 'diet-friendly',
    benefits: ['breath'],
    shortDescription: '입 냄새 제거에 좋습니다.',
    detailedDescription: '파슬리는 강아지의 입 냄새를 개선하는 데 유익합니다.',
  },
  {
    id: 63,
    name: '호박씨',
    category: 'safe',
    img: '../Snack/PumpkinSeed.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '면역력 강화에 좋습니다.',
    detailedDescription:
      '호박씨는 비타민과 미네랄이 풍부하여 면역력을 높여줍니다.',
  },
  {
    id: 64,
    name: '레몬',
    category: 'safe',
    img: '../Snack/Lemon.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '면역력 강화에 좋습니다.',
    detailedDescription: '레몬은 비타민 C가 풍부해 면역력 증진에 유익합니다.',
  },
  {
    id: 65,
    name: '마가린',
    category: 'unsafe',
    img: '../Snack/Margarine.png',
    benefits: [],
    shortDescription: '높은 지방 함량이 강아지에게 해롭습니다.',
    detailedDescription:
      '마가린은 지방이 많아 강아지의 소화에 부담을 줄 수 있습니다.',
  },
  {
    id: 66,
    name: '파르메산 치즈',
    category: 'unsafe',
    img: '../Snack/ParmesanCheese.png',
    benefits: [],
    shortDescription: '염분이 높아 강아지에게 해롭습니다.',
    detailedDescription:
      '파르메산 치즈는 염분 함량이 높아 소량 섭취도 강아지에게 부정적인 영향을 줄 수 있습니다.',
  },
  {
    id: 67,
    name: '시나몬 롤',
    category: 'unsafe',
    img: '../Snack/CinnamonRoll.png',
    benefits: [],
    shortDescription: '설탕과 시나몬이 포함되어 강아지에게 해롭습니다.',
    detailedDescription:
      '시나몬 롤은 강아지에게 독성이 있는 시나몬과 높은 당분이 포함되어 있습니다.',
  },
  {
    id: 68,
    name: '오렌지',
    category: 'safe',
    img: '../Snack/Orange.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '비타민 C가 풍부합니다.',
    detailedDescription:
      '오렌지는 비타민 C가 많아 면역력을 강화하는 데 도움이 됩니다.',
  },
  {
    id: 69,
    name: '파프리카',
    category: 'safe',
    img: '../Snack/BellPepper.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '비타민 C가 풍부하여 면역력에 좋습니다.',
    detailedDescription:
      '파프리카는 비타민 C와 항산화 성분이 풍부하여 면역력을 높이고 세포 건강에 도움을 줍니다.',
  },
  {
    id: 70,
    name: '체리',
    category: 'unsafe',
    img: '../Snack/Cherry.png',
    benefits: [],
    shortDescription: '시안화물이 포함되어 해롭습니다.',
    detailedDescription:
      '체리 씨에는 시안화물이 포함되어 있어 강아지에게 중독을 일으킬 수 있습니다.',
  },
  {
    id: 71,
    name: '고추',
    category: 'unsafe',
    img: '../Snack/Chili.png',
    benefits: [],
    shortDescription: '위장을 자극할 수 있습니다.',
    detailedDescription:
      '고추는 강아지의 위장에 자극을 줄 수 있어 피하는 것이 좋습니다.',
  },
  {
    id: 72,
    name: '감자',
    category: 'safe',
    img: '../Snack/Potato.png',
    diet: 'non-diet-friendly',
    benefits: ['energy recovery'],
    shortDescription: '에너지 보충에 좋습니다.',
    detailedDescription:
      '감자는 탄수화물이 풍부하여 에너지를 충전하는 데 유용합니다.',
  },
  {
    id: 73,
    name: '팽이버섯',
    category: 'safe',
    img: '../Snack/EnokiMushroom.png',
    diet: 'diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화에 도움이 됩니다.',
    detailedDescription:
      '팽이버섯은 저칼로리 식품으로, 소화 건강을 지원합니다.',
  },
  {
    id: 74,
    name: '새송이버섯',
    category: 'safe',
    img: '../Snack/KingOysterMushroom.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '면역력 강화에 유익합니다.',
    detailedDescription:
      '새송이버섯은 면역력을 높이는 데 도움이 되는 비타민과 항산화 성분이 포함되어 있습니다.',
  },
  {
    id: 81,
    name: '사과 씨',
    category: 'unsafe',
    img: '../Snack/AppleSeed.png',
    benefits: [],
    shortDescription: '시안화물이 포함되어 있습니다.',
    detailedDescription:
      '사과 씨는 시안화물을 포함하고 있어 강아지에게 독성이 있습니다.',
  },
  {
    id: 82,
    name: '사람용 비타민',
    category: 'unsafe',
    img: '../Snack/HumanIronVitamin.png',
    benefits: [],
    shortDescription: '강아지에게 철분 과다 위험이 있습니다.',
    detailedDescription:
      '사람용 비타민은 철분 성분이 있을 수 있어 강아지에게 철분 과다를 일으켜 간 손상 및 위장 장애를 유발할 수 있습니다.',
  },
  {
    id: 83,
    name: '아기용 음식 (사람용)',
    category: 'unsafe',
    img: '../Snack/BabyFood.png',
    benefits: [],
    shortDescription: '양파 분말과 영양 불균형의 위험이 있습니다.',
    detailedDescription:
      '일부 아기용 음식은 양파 분말이 들어 있을 수 있으며, 강아지에게 영양 부족을 일으킬 수 있습니다.',
  },
  {
    id: 84,
    name: '오징어 및 건어물',
    category: 'unsafe',
    img: '../Snack/DriedSquid.png',
    benefits: [],
    shortDescription: '소화 불량과 구토를 일으킬 수 있습니다.',
    detailedDescription:
      '오징어와 건어물은 소화가 어려우며, 큰 덩어리는 정체되어 부패하거나 구토를 일으킬 수 있습니다.',
  },
  {
    id: 85,
    name: '자몽',
    category: 'unsafe',
    img: '../Snack/Grapefruit.png',
    benefits: [],
    shortDescription: '강한 산성으로 인해 위장에 해로울 수 있습니다.',
    detailedDescription:
      '자몽은 강한 산성으로 인해 강아지의 위장을 자극할 수 있습니다.',
  },
  {
    id: 86,
    name: '딸기',
    category: 'safe',
    img: '../Snack/Strawberry.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '면역력 강화에 좋습니다.',
    detailedDescription:
      '딸기는 비타민 C와 항산화 성분이 풍부하여 면역력 강화에 도움을 줍니다.',
  },
  {
    id: 87,
    name: '복숭아',
    category: 'unsafe',
    img: '../Snack/Peach.png',
    benefits: [],
    shortDescription: '씨앗에 독성 성분이 있어 위험합니다.',
    detailedDescription:
      '복숭아 씨앗에는 독성 물질이 포함되어 있어 강아지에게 해로울 수 있습니다.',
  },
  {
    id: 88,
    name: '라즈베리',
    category: 'safe',
    img: '../Snack/Raspberry.png',
    diet: 'diet-friendly',
    benefits: ['antioxidant'],
    shortDescription: '항산화 작용이 뛰어납니다.',
    detailedDescription:
      '라즈베리는 항산화 성분이 많아 세포 건강에 도움을 줍니다.',
  },
  {
    id: 89,
    name: '무화과',
    category: 'safe',
    img: '../Snack/Fig.png',
    diet: 'diet-friendly',
    benefits: ['digestion'],
    shortDescription: '소화 기능에 도움을 줍니다.',
    detailedDescription:
      '무화과는 소화 효소가 포함되어 있어 소화를 촉진하는 데 유익합니다.',
  },
  {
    id: 90,
    name: '토마토',
    category: 'unsafe',
    img: '../Snack/Tomato.png',
    benefits: [],
    shortDescription: '알칼로이드가 강아지에게 해로울 수 있습니다.',
    detailedDescription:
      '토마토에는 솔라닌이라는 알칼로이드가 포함되어 있어 강아지에게 해로울 수 있습니다.',
  },
  {
    id: 91,
    name: '구아바',
    category: 'safe',
    img: '../Snack/Guava.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '면역력을 높이는 데 유용합니다.',
    detailedDescription:
      '구아바는 비타민 C가 풍부하여 면역력 강화에 유익합니다.',
  },
  {
    id: 92,
    name: '참외',
    category: 'safe',
    img: '../Snack/KoreanMelon.png',
    diet: 'diet-friendly',
    benefits: ['hydration'],
    shortDescription: '수분 보충에 좋습니다.',
    detailedDescription:
      '참외는 수분 함량이 높아 체내 수분 보충에 도움을 주며, 저칼로리 간식으로 적합합니다.',
  },
  {
    id: 93,
    name: '배',
    category: 'safe',
    img: '../Snack/Pear.png',
    diet: 'diet-friendly',
    benefits: ['digestion', 'hydration'],
    shortDescription: '소화에 도움을 주며 수분 보충에 유용합니다.',
    detailedDescription:
      '배는 수분 함량이 높고 섬유질이 풍부하여 소화와 수분 공급에 좋습니다.',
  },
  {
    id: 94,
    name: '방울토마토',
    category: 'unsafe',
    img: '../Snack/CherryTomato.png',
    benefits: [],
    shortDescription: '알칼로이드 성분으로 인해 강아지에게 해롭습니다.',
    detailedDescription:
      '방울토마토에는 솔라닌 성분이 포함되어 있어 강아지에게 독성을 나타낼 수 있습니다.',
  },
  {
    id: 95,
    name: '씨앗을 제거한 복숭아',
    category: 'safe',
    img: '../Snack/Peach.png',
    diet: 'diet-friendly',
    benefits: ['immune system'],
    shortDescription: '씨앗만 제거하면 안전하게 섭취할 수 있습니다.',
    detailedDescription:
      '복숭아는 씨앗을 제거한 후 섭취하면 비타민 C와 항산화 성분이 강아지의 면역력을 강화하는 데 도움이 됩니다.',
  },
  {
    id: 96,
    name: '파워에이드',
    category: 'unsafe',
    img: '../Snack/Powerade.png',
    benefits: [],
    shortDescription: '강아지에게 해로운 당분과 인공 성분이 포함되어 있습니다.',
    detailedDescription:
      '파워에이드는 당분과 인공 첨가물이 많아 강아지에게 부정적인 영향을 줄 수 있습니다.',
  },
  {
    id: 97,
    name: '포카리스웨트',
    category: 'unsafe',
    img: '../Snack/PocariSweat.png',
    benefits: [],
    shortDescription: '인공 성분과 높은 나트륨 함량으로 강아지에게 해롭습니다.',
    detailedDescription:
      '포카리스웨트는 강아지에게 불필요한 나트륨과 당분이 포함되어 있어 적합하지 않습니다만 장염같은 설사시 복용시킬순 있습니다.',
  },
  {
    id: 98,
    name: '게토레이',
    category: 'unsafe',
    img: '../Snack/Gatorade.png',
    benefits: [],
    shortDescription: '인공 성분과 당분이 포함되어 있어 강아지에게 해롭습니다.',
    detailedDescription:
      '게토레이는 인공 색소와 높은 당분 함량으로 인해 강아지에게 해로울 수 있습니다.',
  },
  {
    id: 99,
    name: '우유',
    category: 'unsafe',
    img: '../Snack/Milk.png',
    benefits: [],
    shortDescription: '유당 불내증을 유발할 수 있습니다.',
    detailedDescription:
      '우유는 강아지에게 유당 불내증을 유발하여 설사나 소화 불편을 일으킬 수 있습니다.',
  },
  {
    id: 100,
    name: '레몬주스',
    category: 'unsafe',
    img: '../Snack/LemonJuice.png',
    benefits: [],
    shortDescription: '강한 산성으로 인해 위장에 자극을 줄 수 있습니다.',
    detailedDescription:
      '레몬주스는 강한 산성을 가지고 있어 위장에 부담을 주고 소화 장애를 유발할 수 있습니다.',
  },
  {
    id: 101,
    name: '오렌지주스',
    category: 'unsafe',
    img: '../Snack/OrangeJuice.png',
    benefits: [],
    shortDescription: '산성과 당분이 높아 강아지에게 해롭습니다.',
    detailedDescription:
      '오렌지주스는 강아지의 위장을 자극할 수 있으며, 높은 당분 함량이 강아지에게 적합하지 않습니다.',
  },
  {
    id: 102,
    name: '자몽주스',
    category: 'unsafe',
    img: '../Snack/GrapefruitJuice.png',
    benefits: [],
    shortDescription: '강한 산성과 자몽의 독성 성분이 강아지에게 해롭습니다.',
    detailedDescription:
      '자몽주스는 산성이 강하고 자몽 특유의 성분이 있어 강아지의 위장에 부담을 줄 수 있습니다.',
  },
];

export default function getSnackList() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(snackData);
    }, 300);
  });
}
